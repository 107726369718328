

jQuery.fn.exists = function () {
    return this.length > 0;
};

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('div.product-chooser').not('.disabled').find('div.product-chooser-item').on('click', function () {
    $(this).parent().parent().find('div.product-chooser-item').removeClass('selected');
    $(this).addClass('selected');
    $(this).find('input[type="radio"]').prop("checked", true);
    $(this).find('input[type="radio"]').change();
});

var App = (function () {

    'use strict';

    var $document = $(document);

    var filestyleOptions = {
        input: false,
        iconName: 'fa fa-folder-open-o'
    };

    var DATE_TIME_PICKER_FORMATS = {
        DATE: "YYYY-MM-DD",
        DATE_TIME: "YYYY-MM-DD HH:mm"
    };


    var _init = function () {
        _boot();
    };

    var _boot = function () {

        _initDashboardMenu();

        _initSelect2();

        _initDateTimePickers();

        _initBootstrapFilestyle();

        _initBootstrapComponents();

        _initLightBox();

        _setListeners();

        _layoutListeners();

    };

    var _initLightBox = function () {
        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
            event.preventDefault();
            $(this).ekkoLightbox();
        });
    };

    var _initBootstrapComponents = function () {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    };

    /**
     * Set view template listeners
     *
     * @private
     */
    var _layoutListeners = function () {

        ApplicationListeners.init();

    };

    var _setListeners = function () {

        $document.on('click', '.add-new-certificate-row', _addDogCertificateRow);
        $document.on('click', '.delete-certificate-row', _deleteCertificateRow);
        $document.on('click', ".approval-info-modal", _clubDogApprovingInfo);
        $document.on('click', ".showcase-mark-to-verification", _changeShowcaseBaStatus);
        $document.on('click', ".delete-record-from-table", _deleteRowFromTable);
        $document.on('click', ".preview-showcase", _previewShowcase);

    };

    var _previewShowcase = function (event) {
        event.preventDefault();
        var target = $(this).attr("href");

        Loader.start();
        // load the url and show modal on success
        $(".modal-content", "#default-modal").load(target, function() {
            $("#default-modal").modal("show");
            Loader.stop();
        });

    };

    /**
     * Delete row from table
     *
     * @param event
     * @private
     */
    var _deleteRowFromTable = function (event) {
        event.preventDefault();

        var $modal = $('#deleteModal'),
            deleteURL = $(this).attr('href');

        $modal.modal('show');

        $('#deleteModalConfirm').on('click', function (e) {
            $.ajax({
                url: deleteURL,
                type: 'DELETE',
                success: function (result) {
                    window.location.reload();
                }
            });
        });
    };

    /**
     * Change ba status for showcase
     * List or edit view
     *
     * @param event
     * @private
     */
    var _changeShowcaseBaStatus = function ( event ) {

        var showcase_id = $(this).data('showcase-id');
        if (showcase_id > 0) {
            $.ajax({
                url: '/showcases/' + showcase_id + '/verify',
                type: 'POST',
                beforeSend: Loader.start,
                complete: Loader.stop,
                success: function (data) {
                    console.log(data);
                    window.location.reload();
                }
            });
        }

    };

    var _clubDogApprovingInfo = function (event) {

        event.preventDefault();
        var target = $(this).data("remote");

        Loader.start();
        // load the url and show modal on success
        $(".modal-content", "#approval-info-modal").load(target, function() {
            $("#approval-info-modal").modal("show");
            Loader.stop();
        });

    };

    var _addDogCertificateRow = function () {

        var indexes = $('[data-certificate-index]', '#dog-certificates').map(function () {
            return parseInt($(this).data('certificate-index'));
        }).get();
        var maxIndex = Math.max.apply(null, indexes);

        var template = $('#dog-certificate-row-template').html();
        template = template.replace(/{index}/g, maxIndex + 1);

        $('#dog-certificates').append(template);

        _restartSelect2();

        _initBootstrapFilestyle();

    };

    var _deleteCertificateRow = function () {

        var $wrapper = $(this).closest('.dog-certificate');

        $wrapper.hide();
        $wrapper.find('.certificate-deleted').val(1);

    };

    /**
     * Init bootstrap filestyle plugin
     *
     * @private
     */
    var _initBootstrapFilestyle = function () {

        $(".my-filestyle:visible").each(function () {
            var filestyle = $(this).data('filestyle');
            if (typeof filestyle === 'undefined') {
                $(this).filestyle(filestyleOptions);
            }
        });

    };

    /**
     * Restart Bootstrap filestyle
     *
     * @private
     */
    var _restartBootstrapFilestyle = function () {

        $(".my-filestyle:visible").each(function (elem) {
            var filestyle = $(this).data('filestyle');
            if (typeof filestyle !== 'undefined') {
                $(this).filestyle('destroy');
            }
            $(this).filestyle(filestyleOptions);
        });

    };

    /**
     * Initialize select2 plugin
     *
     * @private
     */
    var _initSelect2 = function () {

        $.fn.select2.defaults.set("theme", "bootstrap");

        $('select.select2:visible').select2();

        $("select.select2-ajax:visible").each(function (elem) {
            var $this = $(this);

            console.log();

            var defaultOption = $this.data('default-option') || {};

            $this.select2({
                ajax: {
                    url: $this.data('remote'),
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term, // search term
                            page: params.page
                        };
                    },
                    processResults: function (data, params) {
                        params.page = params.page || 1;

                        // prepend default option into response array
                        if ( params.page === 1 && typeof defaultOption === 'object' && defaultOption.hasOwnProperty('id') ) {
                            data.data.unshift(defaultOption);
                        }

                        return {
                            results: data.data,
                            pagination: {
                                more: (params.page * 20) < data.total
                            }
                        };
                    },
                    cache: false
                },
                escapeMarkup: function (markup) {
                    return markup;
                },
                minimumInputLength: 1
            });

            $this.on('select2:opening', function (event) {
                if ( typeof defaultOption === 'object' && defaultOption.hasOwnProperty('id') ) {
                    $(this).append(new Option(defaultOption.text, defaultOption.id, true, true));
                }
            });

            //if ( typeof defaultOption === 'object' && defaultOption.hasOwnProperty('id') ) {
            //    $(this).append(new Option(defaultOption.text, defaultOption.id, true, true));
            //}
        });
    };

    var _restartSelect2 = function () {

        $('select.select2:visible, select.select2-ajax:visible').each(function (elem) {
            var $this = $(this);
            var select2Instance = $this.data('select2');
            if (typeof select2Instance !== 'undefined') {
                var resetOptions = select2Instance.options.options;
                $this.select2('destroy').select2(resetOptions);
            } else {
                $this.select2();
            }
        });

    };

    /**
     * Initialize dashboard menu
     *
     * @private
     */
    var _initDashboardMenu = function () {
        if ($('.info .items').exists()) {
            $('.info .items > li').has('.drop').addClass('has-drop');
        }

        if ($('.has-drop').exists()) {
            $('.has-drop').hover(function () {
                $(this).addClass('hover');
            }, function () {
                $(this).removeClass('hover');
            });
        }
    };

    /**
     * Initialize datetime pickers
     *
     * @private
     */
    var _initDateTimePickers = function () {

        var _icons = {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-calendar',
            clear: 'fa fa-trash',
            close: 'fa fa-times'
        };

        if ($(".timepicker").exists()) {
            $('.timepicker').timepicker({
                'showMeridian': true,
                'showInputs': false,
                'modalBackdrop': true,
                minuteStep: 5,
                icons: _icons
            });
        }


        if ($(".datetimepicker").exists()) {
            $('.datetimepicker').datetimepicker({
                sideBySide: true,
                collapse: false,
                useStrict: true,
                format: 'YYYY-MM-DD HH:mm',
                icons: _icons
            });
        }

        $('.datepicker').each(function (index) {

            if (typeof $(this).data('date-format') !== 'undefined') {
                $(this).datetimepicker({
                    format: 'yyyy-mm-dd',
                    //autoclose: true,
                    //showTimepicker: false
                    icons: _icons
                });
            } else {
                $(this).datetimepicker({
                    format: 'YYYY-MM-DD',
                    //autoclose: true,
                    //showTimepicker: false,
                    icons: _icons
                });
            }

        });

    };

    return {
        init: _init,
        initSelect2: _initSelect2,
        DATE_TIME_PICKER_FORMATS: DATE_TIME_PICKER_FORMATS
    };

})();

/**
 * Application listeners
 *
 */
var ApplicationListeners = (function () {

    "use strict";

    var $body = $('body');
    var $wrapper = $('#wrapper');

    var _init = function () {
        _setListeners();
        _getMembershipNo();
    };

    /**
     * Set application styles listeners
     *
     * @private
     */
    var _setListeners = function () {

        $(".user-area").mouseover(function () {
            $(".user-area").attr('class', 'user-area hover');
        });
        $(".user-area").mouseout(function () {
            $(".user-area").attr('class', 'user-area');
        });

        $('.btn-open-user-menu').click(_openMenu);
        $('.btn-open-main-nav').click(_openMainNav);

        if ($('.notification').exists()) {
            $('.notification').clone().addClass('cloned').appendTo($wrapper);
            $wrapper.append('<a href="#" class="btn-open-user-menu">' + $($('.user-area .avatar').clone()).html() + $('.user-area .name').clone().html() + '</a>');
        }

        if ($('#main-nav').exists()) {
            $('#main-nav .container').clone().addClass('main-nav-cloned').appendTo($wrapper);
            $wrapper.append('<a href="#" class="btn-open-main-nav">Main Navigation<span></span><span></span><span></span></a>');
        }

        if ($('#main-nav .container >ul> li').exists()) {
            $('#main-nav .container >ul> li').has('.drop').addClass('has-drop');
        }

        if ($('.main-nav-cloned').exists()) {
            $('.main-nav-cloned >ul>li').has('.drop').addClass('has-drop');
            $('.main-nav-cloned .has-drop>a').click(_mainNavToggle);
        }

        if ($('.info .items').exists()) {
            $('.info .items > li').has('.drop').addClass('has-drop');
        }

        if ($('.has-drop').exists()) {
            $('.has-drop').hover(function () {
                $(this).addClass('hover');
            }, function () {
                $(this).removeClass('hover');
            });
        }

        $(document).on('change', '#activity', _activityListeners);

    };

    var _activityListeners = function (event) {

        var $option = $(this).find('option:selected');

        var value = $option.val();
        var redirectUrl = $option.data('url');

        if ( typeof redirectUrl !== 'undefined') {
            window.location.href = redirectUrl;
        }

        return false;
    };

    var _overlayClear = function () {
        $body.removeClass('active-menu');
        $body.removeClass('active-user-menu');
        $body.removeClass('active-main-nav');
    };

    var _mainNavToggle = function () {
        if ($(this).parent('li').hasClass('opened')) {
            $(this).parent('li').removeClass('opened').find('.drop').slideUp('fast');
        } else {
            $('.main-nav-cloned >ul>li').removeClass('opened');
            $('.main-nav-cloned .drop').slideUp('fast');
            $(this).parent('li').addClass('opened').find('.drop').slideDown('fast');
        }
        return false;
    };

    var _openMainNav = function () {
        $body.toggleClass('active-menu');
        $body.toggleClass('active-main-nav');
        return false;
    };

    var _openMenu = function () {
        $body.toggleClass('active-menu');
        $body.toggleClass('active-user-menu');
        return false;
    };

    var _getMembershipNo = function () {
        var $container = $('.user-membership-no');
        $.get('/user/get-membership-no', {}, function (data) {
            if ( data.success ) {
                $container.html(' - ' + data.membership_no);
            }
        });
    };

    return {
        init: _init
    };

})();

$(function () {
    App.init();
});

